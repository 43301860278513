import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'next-i18next';

export type BackMainMenuProps = {
  onClick(): void;
};

const BackMainMenu = ({ onClick }: BackMainMenuProps) => {
  const { t } = useTranslation('common');
  return (
    <MenuItem className="back-menu" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} style={{ width: '12px', height: '12px', marginRight: '1rem' }} />
      {t('back_main_menu')}
    </MenuItem>
  );
};

export default BackMainMenu;
