import { faUserAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UserMenuProfileStyle from './style';

import { IUserProfile } from '@interfaces/user';

type UserMenuProfileProps = {
  isMobile?: boolean;
  profile?: IUserProfile;
};

const UserMenuProfile = ({ isMobile, profile }: UserMenuProfileProps) => {
  const { firstname, lastname, email, avatar } = profile || {};

  return (
    <UserMenuProfileStyle>
      <Box p={2} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
        <Avatar alt="image-profile" className="image-profile" src={avatar}>
          <FontAwesomeIcon icon={faUserAlt} />
        </Avatar>
        <Box className="profile-container" display="flex" flexDirection="column" justifyContent="center">
          <Typography className="full-name" noWrap>
            {firstname} {lastname}
          </Typography>
          <Typography className="email" noWrap>
            {email}
          </Typography>
        </Box>
      </Box>
    </UserMenuProfileStyle>
  );
};

export default UserMenuProfile;
